<template>
  <div class="m-container__wrapper">
    <div
      v-for="(article, index) in articles"
      :key="article.id"
      class="m-article-side-playlist-item px-2"
      :class="{
        'm-playing': article.id === articleId,
      }"
    >
      <label
        v-if="article.id !== articleId"
        class="type--small type--500 pa-1"
        >{{ index + 1 }}</label
      >
      <m-icon v-else icon="play" />
      <div class="m-articles__wrapper m-articles__wrapper--details">
        <m-article :article="article" layout="details"></m-article>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useApi } from "@root/api/api";
import type { MonitioAPI } from "@root/types.api.local";
import { onMounted, ref } from "vue";
import MArticle from "@components/MArticle.vue";
import MIcon from "./MIcon.vue";

interface Props {
  parentId: string;
  articleId: string;
}

const props = defineProps<Props>();

const { api } = useApi();

const articles = ref<MonitioAPI.SearchDocumentDTO[]>();
const getChildrenArticles = async (): Promise<
  MonitioAPI.SearchDocumentDTO[]
> => {
  const result = await api.search!.getChildren(props.parentId);

  return result.data.result?.documents ?? [];
};

onMounted(async () => {
  articles.value = await getChildrenArticles();
});
</script>

<style scoped lang="scss">
.m-article-side-playlist-item {
  display: flex;
  justify-items: center;
  align-items: center;
  transition: background-color 80ms ease-in-out 0ms;

  &.m-playing {
    background-color: color($pri-action, 0.05);
  }

  &:hover {
    background-color: color($pri-action, 0.2);
  }
}

:deep(.m-article__title) {
  font-size: 1rem !important;
}

:deep(.m-articles__wrapper .m-article:hover .m-article__container) {
  background-color: transparent !important;
  box-shadow: none;
}
</style>
